@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@page {
    margin: 60px 35px 35px;
}
@page :first {
    margin-top: 35px;
}
.template_test {
    font-family: "Lato";
    font-size: 10pt;
}
.template_test .pageBreak {
    page-break-before: always;
}
.template_test .blankLine {
    display: block;
    border-bottom: 1px solid #FFFFFF;
}
.template_test .keepTogether {
    page-break-inside: avoid;
}
.template_test h1 {
    font-size: 22pt;
    text-align: center;
    padding: 15px 0;
    margin: 15px 0;
    border-bottom: 2px solid #FFFFFF;
    font-weight: 900;
}
.template_test h2 {
    text-align: center;
    font-size: 14pt;
    text-transform: uppercase;
    font-weight: 700;
    overflow: hidden;
    margin: 20px 0;
}
.template_test h2::before,
.template_test h2::after {
    background-color: #FFFFFF;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
}
.template_test h2::before {
    right: 10px;
    margin-left: -50%;
}
.template_test h2::after {
    left: 10px;
    margin-right: -50%;
}
.template_test .contactInfo {
    text-align: center;
    margin: 15px 0;
}
.template_test .contactInfo span::before {
    content: " | ";
    padding: 0 5px;
}
.template_test .contactInfo span:first-child::before {
    content: "";
    padding: 0;
}
.template_test p {
    margin: 20px 0;
}
.template_test .infoHeading {
    margin: 20px 0 10px;
    position: relative;
}
.template_test .infoHeading .mainTitle,
.template_test .infoHeading .mainInfo {
    font-weight: 900;
    font-size: 12pt;
    display: block;
    max-width: 50%;
}
.template_test .infoHeading .subTitle,
.template_test .infoHeading .subInfo {
    font-weight: 700;
    font-size: 11pt;
    display: block;
    max-width: 50%;
}
.template_test .infoHeading .mainInfo {
    position: absolute;
    top: 0;
    right: 0;
}
.template_test .infoHeading .subInfo {
    position: absolute;
    bottom: 0;
    right: 0;
}
.template_test .infoHeading.education .subInfo {
    font-weight: normal;
    font-style: italic;
    position: static;
    display: block;
    font-size: 10pt;
    max-width: 100%;
}
.template_test .infoHeading.award .subInfo,
.template_test .infoHeading.volunteer .subInfo {
    font-weight: normal;
    position: static;
    display: block;
    font-size: 10pt;
    max-width: 100%;
}
.template_test ul {
    margin: 0;
    list-style-type: none;
}
.template_test ul li::before {
    content: "â€¢";
    position: absolute;
    left: 20px;
}
.template_test li {
    margin: 10px 0;
    padding-left: 33px;
    position: relative;
    box-sizing: border-box;
}
.template_test .columns {
    font-size: 0;
}
.template_test .columns li {
    display: inline-block;
    width: 50%;
    font-size: 10pt;
    margin: 5px 0;
    vertical-align: top;
}
.template_test .workSample {
    margin-left: 20px;
}
.ua-mobile .template_test h2::before,
.ua-mobile .template_test h2::after {
    height: 2px;
}
.ua-android.ua-webkit .template_test .columns {
    font-size: 10pt;
}
.ua-android.ua-webkit .template_test .columns li {
    width: 49%;
}
body,
html {
    color: #FFFFFF
}
noscript,
#navigation,
#banner,
#footer,
#intro,
#outtro,
.ImportantInfoDialog,
.headerSimplePreview,
.sectionMenuTop,
.selLicense,
.previewHead,
iframe,
iframe *,
.noMobile,
.woahbar,
.contractFootControls,
.previewFormatWarning {
    display: none;
}
#upsell,
#popupContainer,
#popupMask,
.contentBreak,
.contentBreakEnd {
    display: none !important;
}
#popupContainer,
#popupMask {
    visibility: hidden;
}
#wrapper,
#content {
    background: none;
    position: relative;
    display: block;
    width: auto;
}
#outputPage {
    display: block;
    padding: 3px;
    background: inherit;
    color: #FFFFFF;
    position: relative;
    mso-style-parent: "";
    margin: 0;
    margin-bottom: 0.0001pt;
    mso-pagination: widow-orphan;
    tab-stops: -0.5in;
    font-size: 12pt;
    font-family: "Times New Roman", serif;
    mso-fareast-font-family: "Times New Roman";
    line-height: 18pt;
}
#outputPage {
    width: 100%;
    background: none;
}
#outputPage table {
    border-collapse: collapse;
}
#outputPage ol {
    list-style: decimal;
}
#outputPage ol ol {
    list-style: lower-alpha;
}
#outputPage ol ol ol {
    list-style: lower-roman;
}
#outputPage ol ol ol ol {
    list-style: decimal;
    list-style-position: inherit;
}
#outputPage br.pageBreak {
    page-break-before: always;
}

@media screen {
    #outputPage br.pageBreak {
        display: none;
    }
}
#outputPage .outputVersion2 p,
#outputPage .outputVersion2 ol,
#outputPage .outputVersion2 ul,
#outputPage .outputVersion2 table {
    margin-bottom: 0;
}
#outputPage .outputVersion2 ol,
#outputPage .outputVersion2 ul {
    margin-left: 0;
    padding-left: 0;
}
#outputPage .outputVersion2 li > div {
    display: table;
}
#outputPage .format-doc .outputVersion2 li,
#outputPage .format-rtf .outputVersion2 li,
#outputPage .format-docx .outputVersion2 li,
#outputPage .format-pdf .outputVersion2 li {
    margin-left: 18pt;
}
#outputPage .outputVersion2 {
    padding-top: 43pt;
}
.LDCopyright {
    font-size: 67%;
}
.LDCellCenter {
    text-align: center;
}
.LDCellPadLeft {
    padding-left: 100px;
}
.LDCellRight {
    text-align: right;
}
.LDResRentalBox {
    width: 100%;
}
.LDQuitclaimHead {
    height: 3in;
}
.LDWarrantyHead {
    height: 3in;
}
.LDQuitclaimHeadCA {
    height: 1in;
}
.LDWarrantyHeadCA {
    height: 1in;
}
.LDWarrantyHeadMO {
    height: 2in;
}
.LDQuitclaimHeadMO {
    height: 2in;
}
.SubHeadStyle {
    font-weight: bold;
    text-decoration: underline;
}
.SectionTitle {
    font-weight: bold;
    text-decoration: underline;
    margin: 1em 0;
}
.invoiceBorder {
    border: 1px solid #FFFFFF;
}
.invoiceGreyBG {
    background-color: #ccc;
}
.LDBold {
    font-weight: bold;
}
.LDBackgroundOL {
    list-style-type: upper-alpha;
}
#bottomTabs,
#topTabs,
#introContent,
#loadingIndicator,
#questionpage,
#documentControls,
#questionpage {
    display: none;
}
#productContent,
#documentContent {
    width: 100%;
    border: 0;
    margin: 0;
}
#outputPage li {
    margin-bottom: 1em;
}
#outputPage li li {
    margin-bottom: 1em;
}

#outputPage li ol {
    margin-top: 1em;
}
#outputPage li ol li {
    margin-bottom: 1em;
}
#outputPage li.lh,
#outputPage li.lhl {
    margin-bottom: 0;
    list-style: none;
}
.FirstMajorListHeading {
    font-weight: bold;
    margin-bottom: 1.6em;
}
.FirstListHeading {
    font-weight: bold;
    text-decoration: underline;
    font-size: 12pt;
    margin: 1.4em 0 0.2em 2em; /* 2em should be set to left margin of lists */
}
.ListHeading {
    font-weight: bold;
    text-decoration: underline;
}

div.header,
div.firstHeader,
div.footer,
div.firstFooter {
    display: none;
}

.blankLine {
    display: block;
    border-bottom: solid 1px #FFFFFF;
}

/* End Alerts/Confirms Inline Dialogs */
/* Mobile Scrollbars */
.ua-mobile .contractbody::-webkit-scrollbar,
.ua-mobile #contractbody::-webkit-scrollbar,
.ua-mobile .groupNavigationInner > ul::-webkit-scrollbar,
.ua-mobile #contentTopInnerDisclaimer::-webkit-scrollbar,
.ua-mobile #contentTopInnerPrivacy::-webkit-scrollbar,
.ua-mobile #contentTopInnerEditorialPolicy::-webkit-scrollbar,
.ua-mobile #contentTopInnerTerms::-webkit-scrollbar,
.ua-mac_os_x .contractbody::-webkit-scrollbar,
.ua-mac_os_x #contractbody::-webkit-scrollbar,
.ua-mac_os_x .groupNavigationInner > ul::-webkit-scrollbar,
.ua-mac_os_x #contentTopInnerDisclaimer::-webkit-scrollbar,
.ua-mac_os_x #contentTopInnerPrivacy::-webkit-scrollbar,
.ua-mac_os_x #contentTopInnerEditorialPolicy::-webkit-scrollbar,
.ua-mac_os_x #contentTopInnerTerms::-webkit-scrollbar,
.ua-mobile #DIVContractList::-webkit-scrollbar,
.mac_os_x #DIVContractList::-webkit-scrollbar,
.ua-mobile #tip::-webkit-scrollbar,
.ua-mac_os_x #tip::-webkit-scrollbar,
.ua-mac_os_x ul.ui-autocomplete::-webkit-scrollbar,
.ua-mobile ul.ui-autocomplete::-webkit-scrollbar,
.ua-mobile:not(.ua-ios)
    .inlineFooterDialog
    .ui-dialog-content::-webkit-scrollbar,
.addRecipientDialog::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}
.ua-mobile .contractbody::-webkit-scrollbar-thumb,
.ua-mobile #contractbody::-webkit-scrollbar-thumb,
.ua-mobile .groupNavigationInner > ul::-webkit-scrollbar-thumb,
.ua-mobile #contentTopInnerDisclaimer::-webkit-scrollbar-thumb,
.ua-mobile #contentTopInnerPrivacy::-webkit-scrollbar-thumb,
.ua-mobile #contentTopInnerEditorialPolicy::-webkit-scrollbar-thumb,
.ua-mobile #contentTopInnerTerms::-webkit-scrollbar-thumb,
.ua-mac_os_x .contractbody::-webkit-scrollbar-thumb,
.ua-mac_os_x #contractbody::-webkit-scrollbar-thumb,
.ua-mac_os_x .groupNavigationInner > ul::-webkit-scrollbar-thumb,
.ua-mac_os_x #contentTopInnerDisclaimer::-webkit-scrollbar-thumb,
.ua-mac_os_x #contentTopInnerPrivacy::-webkit-scrollbar-thumb,
.ua-mac_os_x #contentTopInnerEditorialPolicy::-webkit-scrollbar-thumb,
.ua-mac_os_x #contentTopInnerTerms::-webkit-scrollbar-thumb,
.ua-mac_os_x #DIVContractList::-webkit-scrollbar-thumb,
.ua-mobile #DIVContractList::-webkit-scrollbar-thumb,
.ua-mobile #tip::-webkit-scrollbar-thumb,
.ua-mac_os_x #tip::-webkit-scrollbar-thumb,
.ua-mac_os_x ul.ui-autocomplete::-webkit-scrollbar-thumb,
.ua-mobile ul.ui-autocomplete::-webkit-scrollbar-thumb,
.ua-mobile:not(.ua-ios)
    .inlineFooterDialog
    .ui-dialog-content::-webkit-scrollbar-thumb,
.addRecipientDialog::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
/* End special links */
/* General DCS Output Styles */
body li > ol:first-child,
body li > ul:first-child {
    margin-top: 0;
}
.ua-edge body li > ol:first-child,
.ua-edge body li > ul:first-child,
.ua-ie body li > ol:first-child,
.ua-ie body li > ul:first-child {
    margin-top: -24px;
}
body li ol,
body li ul {
    margin-top: 1em;
}
body li {
    margin-bottom: 1em;
}
body li.lh,
body li.lhl {
    margin-bottom: 0;
}
body th {
    font-weight: normal;
}
body td > p:first-child,
body th > p:first-child {
    margin-top: 0;
}
body td > p:last-child,
body th > p:last-child {
    margin-bottom: 0;
}
body br.pageBreak {
    display: none;
}
body .blankLine {
    display: block;
    border-bottom: solid 1px black;
}
body ol,
body ul {
    margin-left: 0;
    padding-left: 0;
}
body .header,
body .footer,
body .firstHeader,
body .firstFooter,
body .printWatermark {
    display: none;
}
body li {
    padding-left: 25px;
    margin-left: 15px;
}
body #outputPage,
body .documentContent,
body .contract {
    padding: 2em;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    overflow-x: hidden;
}
body .outputVersion2 li.lhl {
    display: block;
}
body .outputVersion2 ol ol {
    list-style: lower-alpha;
}
body .outputVersion2 ol ol ol {
    list-style: lower-roman;
}
body .outputVersion2 li > div {
    display: table;
    margin: 0 !important;
    padding: 0 !important;
}
.LD body #contractbody li,
.LD body .contractbody li {
    padding-left: 25px;
    margin-left: 30px;
}
body .outputVersion1:not(.templated) *,
body .outputVersion2:not(.templated) * {
    font-family: "Times New Roman", serif;
    font-size: 12pt;
    line-height: 18pt;
}
body .outputVersion1:not(.templated) * ul,
body .outputVersion2:not(.templated) * ul {
    list-style: disc;
}
body .outputVersion1:not(.templated) * .lh,
body .outputVersion1:not(.templated) * .lhl,
body .outputVersion2:not(.templated) * .lh,
body .outputVersion2:not(.templated) * .lhl {
    list-style: none;
}
body .outputVersion1:not(.templated) * strong,
body .outputVersion2:not(.templated) * strong {
    font-weight: 700;
}

@media only screen and (max-device-width: 1024px) {
    body {
        -webkit-text-size-adjust: 100%;
        -moz-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
    }
}
