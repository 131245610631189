@import url('https://fonts.googleapis.com/css2?family=Lavishly+Yours&display=swap');

body {
  background: linear-gradient(180deg, rgba(163,0,0,1) 0%, rgba(60,0,0,1) 20%, rgba(0,0,0,1) 40%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(2rem + 2vmin);
  color: #dedede;
  font-family: "Lavishly Yours", cursive, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
